/** @jsx jsx */
import { Box, Text, jsx, Image } from "theme-ui";

import { uniq } from "sites-common/utils/lodash";
import PropTypes from "prop-types";
import { Link, Stack, StackItem } from "office-ui-fabric-react";
import { abhyasStage, SEEKER } from "sites-common/utils/validations";
// import SearchAbhyasi from "../../SrcmModels/SearchAbhyasi";
import SearchAbhyasiWithId from "../../SrcmModels/SearchAbhyasiWithId";
import IDCard from "../../IDCard";
import ProfileImage from "../../../imgs/meditator.jpg";
import renderPersonResultPlain from "./renderPersonResultPlain";
import SearchPreceptor from "../../SrcmModels/SearchPreceptor";

const NA = <Text sx={{ fontSize: "0.8em" }}>⋅ ⋆ Not Available ⋆ ⋅</Text>;

const renderAbhyasiMaskPII = (item, masked) => {
  const maskit = (s) => {
    if (!s) return NA;
    if (!masked) return s;

    const str = s.replace(/(.{3})(.+)(.{2})/g, (match, start, middle, end) => {
      return start + "⋆".repeat(middle.length) + end;
    });

    return str;
  };

  const phones = uniq([item.phone, item.phone2, item.mobile2])
    .filter((r) => r)
    .map(maskit)
    .join(", ");
  const aStage = abhyasStage(item?.record_type, item?.ref);

  return (
    <Box>
      <div
        sx={{
          boxShadow: "0px 3px 6px #00000029",

          margin: "auto",
          borderRadius: "10px",
          width: "280px",
          minHeight: "400px",
          bg: "white",
        }}
      >
        <Stack horizontalAlign="start" sx={{ p: 3 }}>
          <StackItem>{renderPersonResultPlain(item)}</StackItem>

          <StackItem sx={{ py: 4 }}>
            <Image
              sx={{
                lineHeight: "160px",
                height: "160px",
                textAlign: "center",
                border: "solid 1px grey",
              }}
              src={!masked && item?.photo_url ? item?.photo_url : ProfileImage}
              alt="Photo Missing"
            />
          </StackItem>
          <StackItem>
            <div>
              <Text variant="description">Heartfulness ID:</Text>{" "}
              <Text>{item?.ref || NA}</Text>
            </div>
            <div>
              <Text variant="description">Sign-In Email: </Text>
              <Link href={`mailto:${item?.email}`}>{maskit(item?.email)}</Link>
            </div>

            <div>
              <Text variant="description">Sign-In Mobile: </Text>

              <Text>{maskit(item?.mobile)}</Text>
            </div>
            <div>
              <Text variant="description">Other Phones: </Text>

              <Text>{phones || NA}</Text>
            </div>

            {aStage === SEEKER && (
              <div sx={{ mt: 2, fontSize: "0.8em", color: "darkblue" }}>
                <i>
                  <b>Note:</b> Email/Mobile are not registered with any ID;
                  Either a new seeker or could be a duplicate account
                </i>
              </div>
            )}
          </StackItem>
        </Stack>
      </div>
    </Box>
  );
};

const renderAbhyasiShowPII = (item) => renderAbhyasiMaskPII(item, false);
const renderAbhyasiHidePII = (item) => renderAbhyasiMaskPII(item, true);

export const renderAbhyasiWithPhoto = (item) => {
  return (
    <Box>
      <IDCard showContacts srcmProfile={item} />
    </Box>
  );
};

const RenderAbhyasiDetails = ({
  abhyasiId,
  id,
  title,
  item,
  // showPhoto,
  maskPII,
  // exportPDF,
}) => {
  const render = maskPII ? renderAbhyasiHidePII : renderAbhyasiShowPII;

  // if (exportPDF) {
  //   return (
  //     <PDFRender>
  //       <div sx={{ p: 2, fontSize: "1.1em", bg: "#E4D2BA" }}>
  //         <div>{title}</div>
  //         {item && render(item)}
  //         {!item && abhyasiId && (
  //           <SearchAbhyasi abhyasiId={abhyasiId} render={render} />
  //         )}
  //         {!item && !abhyasiId && (
  //           <SearchAbhyasiWithId id={id} render={render} />
  //         )}
  //       </div>{" "}
  //     </PDFRender>
  //   );
  // }
  return (
    <div sx={{ p: 2, fontSize: "1.1em", bg: "#E4D2BA" }}>
      <div>{title}</div>
      {item && render(item)}
      {!item && abhyasiId && (
        <SearchPreceptor abhyasiId={abhyasiId} render={render} />
      )}
      {!item && !abhyasiId && <SearchAbhyasiWithId id={id} render={render} />}
    </div>
  );
};

RenderAbhyasiDetails.propTypes = {
  id: PropTypes.number,
  abhyasiId: PropTypes.string.isRequired,
  title: PropTypes.string,
  item: PropTypes.shape({}),
  maskPII: PropTypes.bool,
  // exportPDF: PropTypes.bool,
};

RenderAbhyasiDetails.defaultProps = {
  id: 0,
  title: "",
  item: null,
  maskPII: true,
  // exportPDF: false,
};

export default RenderAbhyasiDetails;
