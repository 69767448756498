/** @jsx jsx */
import { jsx } from "theme-ui";

import PropTypes from "prop-types";
import SearchPositions from "../../SrcmModels/SearchPositions";
import SearchId from "../../SrcmModels/SearchId";
import RenderPreceptorDetails from "./RenderPreceptorDetails";

const renderZone = (zone) =>
  ["zone", "region", "whq"].includes(zone.group_type) ? (
    <div>
      <div sx={{ fontSize: "1.3em", fontWeight: 700 }}>{zone.name}</div>

      <SearchPositions
        srcmGroup={zone.id}
        allResultsRender={(r) =>
          r
            .filter(
              (x) => x.active && ["ZC", "RF"].includes(x.position_type_code)
            )
            .map((x) => (
              <div>
                <div sx={{ fontSize: "0.9em", opacity: 0.9, pt: 2 }}>
                  {x.position_type_code}
                </div>
                {x.assigned_partner ? (
                  <div>
                    <div
                      sx={{ fontSize: "0.8em", opacity: 0.9, color: "black" }}
                    >
                      <RenderPreceptorDetails
                        abhyasiId={x.assigned_partner.ref}
                        maskPII={false}
                      />
                    </div>
                  </div>
                ) : (
                  "-NA-"
                )}
              </div>
            ))
        }
      />
    </div>
  ) : (
    <SearchId model="groups" id={zone.parent} render={renderZone} />
  );

const RenderZoneDetailsForCenter = ({ centerId, title }) => {
  return (
    <div
      sx={{
        minWidth: 250,
        p: 2,
        fontSize: "1.1em",
        bg: "#8e44ad",
        color: "white",
      }}
    >
      <div>{title}</div>
      <SearchId
        model="groups"
        id={centerId}
        render={(d) => (
          <div>
            {" "}
            <SearchId model="groups" id={d.parent} render={renderZone} />
          </div>
        )}
      />
    </div>
  );
};
RenderZoneDetailsForCenter.propTypes = {
  centerId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

export default RenderZoneDetailsForCenter;
